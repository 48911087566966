<template>
  <el-drawer
    :visible="isApplicationAlert"
    :direction="direction"
    :before-close="handleClose"
    :with-header="false"
    custom-class="myApplicationDrawer"
    @opened="openedDrawer"
  >
    <div class="body">
      <!-- 关闭抽屉按钮 -->
      <svg
        class="icon close-btn"
        @click="handleClose"
        aria-hidden="true"
        slot="reference"
      >
        <use href="#icon-close1"></use>
      </svg>

      <!-- 顶部盒子 -->
      <div class="top_box">
        <div>
          <div class="all-icon"></div>
          <span class="all">
            <!-- 所有应用程序 -->
            {{ $t("label_tabpage_allapplications") }}
          </span>
        </div>
        <!-- 设置应用程序,需要系统管理员权限展示manageSetup -->
         
        <!-- 先隐藏  
         v-if="manageSetup === 'true'"-->
        <span
          class="settingAppBox"
          @click="toSetting"
          v-if="false"
        >
          {{ $t("c866") }}
        </span>
      </div>

      <!-- 所有应用程序列表 -->
      <div class="content">
        <div class="float_box">
          <div
            class="item"
            v-for="(item, index) in allApplicationList"
            :key="index"
            @click="changeApplication(item)"
          >
            <svg class="icon list-img" aria-hidden="true" slot="reference">
              <use
                v-if="Number(item.appstyle)"
                :href="iconList[Number(item.appstyle) - 1]"
              ></use>
              <!-- 默认展示云图标 -->
              <use v-if="!Number(item.appstyle)" :href="iconList[7]"></use>
            </svg>
            <div class="title">
              {{ item.name }}
            </div>
            <div class="desc">
              {{ item.description }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
/**
 * 应用管理器
 */
// import * as LayoutApi from "../api.js";

export default {
  props: {
    /**
     * 是否展示全部应用程序抽屉
     */
    isApplicationAlert: {
      type: Boolean,
      default: false,
    },
    /**
     * 全部应用程序菜单数据
     */
    allApplicationList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    // applicationIcon: {
    //     ace20220322MarketCl: "#icon-shichangyun-sel", // 市场云
    //     ace20220322Salesloud: "#icon-xiaoshouyun-sel", // 销售云
    //     ace20220315Busicloud: "#icon-shangwuyun-sel", // 商务云
    //     acecustomerserviceC: "#icon-kefuyun-sel", // 客服云
    //     ace20210219FieldSer: "#icon-xianchangyun-sel", // 现场云
    //     ace2020D253B3984Or6Q: "#icon-xiangmuyun-sel", // 项目云
    //     ace2016498D153F6m7xD:'#icon-shichangyun-sel', // 市场
    //     ace201300836012pVG3h:'#icon-xiangmuyun-sel', // 招生
    //     ace20170FF491F6FaisY:'#icon-jiaowujiaoxue-sel', // 教务教学
    //     ace201300836028Xf24E:'#icon-caiwu-sel', // 财务
    //     ace20165E6EEEEFBQGSY:'#icon-shujujichu-sel' // 基础数据
    //   },
    // 生成应用程序图标数组
    let iconList = new Array(50)
      .fill(null)
      .map((item, index) => `#icon-appicon${index + 1}-sel`);
    return {
      direction: "ltr", // 抽屉打开方向
      // allApplicationList: [], // 全部应用程序集合
      iconList,
      // 是否是管理员
      manageSetup: JSON.parse(localStorage.getItem("userInformation"))?.data
        .manageSetup,
    };
  },
  filters: {
    // 默认展示的应用程序图标（白色）
    defaultIcon(val) {
      if (!val) {
        val = 8;
      }
      return `#icon-appicon${val}`;
    },
  },
  methods: {
    // 设置应用程序
    toSetting() {
      // 新页面打开设置应用程序
      // let jumpPath = `${this.$cookies.get(
      //   "domainName"
      // )}/appList.action?setupId=TabSet`;

      let jumpPath = `./setup/#/settings/tabgroup`
      window.open(jumpPath);
    },
    //获取应用程序列表
    // getapplication() {
    //   LayoutApi.getApplicationList().then((res) => {
    //     this.allApplicationList = res.data;
    //   });
    // },
    // 点击应用程序名称，切换应用程序
    changeApplication(app) {
      this.$emit("changeApplication", app.id);
      this.$emit("showApplicaMenuCancal", app.id);
    },
    // Drawer 打开动画结束时的回调
    openedDrawer() {
      document.getElementsByClassName("v-modal")[0].style.left = "52px";
    },
    // 关闭抽屉
    handleClose() {
      this.$emit("showApplicaMenuCancal");
    },
  },
};
</script>

<style lang='scss' scoped>
/* 自定义抽屉样式 */
::v-deep .myApplicationDrawer {
  width: 740px !important;
  // padding: 78px 24px !important;
  background-image: url("~@/assets/images/appBg.svg") !important;
  background-repeat: no-repeat !important;
}
// 最外层盒子
.body {
  height: 100%;
  .close-btn {
    width: 28px;
    height: 28px;
    position: fixed;
    top: 30px;
    left: 753px;
    cursor: pointer;
  }
  //  顶部文字盒子
  .top_box {
    height: 128px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-image: url("~@/assets/images/application/bg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0 37px 0 28px;
    .all-icon {
      display: inline-block;
      width: 4px;
      height: 16px;
      border: 2px solid rgb(0, 81, 224);
      background: rgb(0, 81, 224);
      border-radius: 5px;
      margin-top: 4px;
    }
    .all {
      color: #080707;
      font-size: 18px;
      margin-left: 10px;

      img {
        width: 12px;
        height: 7px;
        margin-right: 10px;
      }
    }
    .settingAppBox {
      color: $--color-primary;
      cursor: pointer;
    }
  }

  // 内容盒子
  .content {
    height: calc(100% - 128px);
    background-color: #f5f5f5;
    position: relative;
    .float_box {
      height: 100%;
      overflow: auto;
      padding: 20px 28px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: auto;
      grid-gap: 20px;
      width: 100%;

      .item {
        padding: 30px;
        background-color: #fff;
        &:hover {
          box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.12);
          background-color: #f7f7fa;
        }
        .list-img {
          width: 36px;
          height: 36px;
          display: inherit;
        }
        .title {
          max-width: 118px;
          font-size: 14px;
          font-weight: bold;
          color: #333333;
          margin: 25px 0;
          max-width: 166px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .desc {
          color: #999999;
          max-width: 166px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }
    }

    .list-item:nth-child(6n) {
      margin-right: 0;
    }
  }
}
</style>